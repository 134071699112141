<template>
  <div>
    <b-form-group
      label="Título"
      label-for="input-title"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-textarea
        id="input-title"
        v-model="$v.survey_question.title.$model"
        :state="validateState('title')"
        aria-describedby="input-title-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-title-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-textarea
        id="input-description"
        v-model="$v.survey_question.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Tipo"
      label-for="input-type"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="input-type"
        :options="QuestionTypes"
        value-field="id"
        text-field="value"
        v-model="$v.survey_question.type.$model"
        :state="validateState('type')"
        aria-describedby="input-type-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-type-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row">
      <div class="col" style="text-align: left">
        <b-button
          v-if="show_delete_button && !isNaN(survey_question.id)"
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDelete"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "SurveyQuestionForm",
  mixins: [validationMixin],
  props: {
    SurveyQuestion: {
      type: Object,
    },
    QuestionTypes: {
      type: Array,
      default: function () {
        return [
          { id: 1, value: "Simple" },
          { id: 2, value: "Multiple" },
        ];
      },
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      survey_question: {
        id: this.SurveyQuestion ? this.SurveyQuestion.id : generateUniqueId(),
        title: this.SurveyQuestion ? this.SurveyQuestion.title : "",
        description: this.SurveyQuestion ? this.SurveyQuestion.description : "",
        image: this.SurveyQuestion ? this.SurveyQuestion.image : null,
        type: this.SurveyQuestion ? this.SurveyQuestion.type : null,
      },
    };
  },
  validations() {
    return {
      survey_question: {
        title: { required },
        description: {},
        type: {},
      },
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.survey_question[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.survey_question.$touch();
      if (this.$v.survey_question.$anyError) {
        return;
      }
      if (isNaN(this.survey_question.id)) this.create();
      else this.update();
    },
    create() {
      this.$restful
        .Post("/cideu/survey-question/", this.survey_question)
        .then((response) => {
          this.survey_question = response;
          this.$emit("created", response);
          toast("Pregunta creada.");
        });
    },
    update() {
      this.$restful
        .Put(
          `/cideu/survey-question/${this.survey_question.id}/`,
          this.survey_question
        )
        .then((response) => {
          this.survey_question = response;
          this.$emit("updated", response);
          toast("Pregunta actualizada.");
        });
    },
    askForDelete() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Pregunta?",
        description: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.delete();
        }
      });
    },
    delete() {
      this.$restful
        .Delete(`/cideu/survey-question/${this.survey_question.id}/`)
        .then(() => {
          this.$emit("deleted", this.survey_question);
          toast("Pregunta eliminada.");
        });
    },
  },
};
</script>

<style>
</style>